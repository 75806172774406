<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Меню</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/" (click)="drawer.close()">Помощник</a>
      <a mat-list-item routerLink="/telegram-bot" (click)="drawer.close()">Телеграм бот</a>
      <a mat-list-item routerLink="/dressing-room" (click)="drawer.close()">Примерочная</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content role="main">
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Genesyx Fansite</span>
    </mat-toolbar>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
