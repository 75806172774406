<div class="carousel">
  <mat-carousel [hideArrows]="false" [proportion]="62.5">
    <mat-carousel-slide [image]="'assets/img/helper1.png'"></mat-carousel-slide>
    <mat-carousel-slide [image]="'assets/img/helper2.png'"></mat-carousel-slide>
    <mat-carousel-slide [image]="'assets/img/helper3.png'"></mat-carousel-slide>
    <mat-carousel-slide [image]="'assets/img/helper4.png'"></mat-carousel-slide>
  </mat-carousel>
</div>
<div>
  <h1>Полноценный игровой помощник.</h1>
  <br>Расширение не нарушает правил игры.
  <br>
  <br>Функционал:
  <br>
  <br>- Позволяет открывать все ссылки в игре в новой вкладке
  <br>- Скрывает большие картинки в центре ускоряя навигацию
  <br>- Запоминает размер чата
  <br>- Помогает выставить параметры для дуэльных боёв
  <br>- Помогает выставить параметры для командных боёв
  <br>
  <br>Оповещает о:
  <br>
  <br>- Начале боя
  <br>- Сообщениях в клановом чате
  <br>- Регулируемый таймер (например для рекламы в чате каждые 10 минут)
  <br>- О конце усталости
  <br>- О конце крафта
  <br>- Напоминает сделать ход
  <br>- Полном здоровье и энергии
  <br>- Приватном сообщении
  <br>- Животных на свалке
  <br>- Системном сообщении
  <br>
  <br>
  <a mat-raised-button
     color="accent"
     target="_blank"
     href="https://chrome.google.com/webstore/detail/genesyx-game-helper/iclnmbgdedngcclmfjpkfjljakllneij"
  >Установить в Chrome</a>
  <br>
  <br>
  <a mat-raised-button
     color="accent"
     target="_blank"
     href="https://addons.mozilla.org/ru/firefox/addon/genesyx-game-helper/"
  >Установить в Firefox</a>
</div>
