import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dressing-room',
  templateUrl: './dressing-room.component.html',
  styleUrls: ['./dressing-room.component.scss']
})
export class DressingRoomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
